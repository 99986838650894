import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

  public _currentUser: User;

  constructor(private _authService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (typeof window !== 'undefined') {
      const defaultAuthData = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);

      if (request.headers?.get('Authorization') === 'Basic ' + defaultAuthData) {
        return next.handle(request);
      }

      if ([environment.changePassword, environment.forgotPassword].some(s => request.url.includes(s))) {
        return next.handle(request);
      }

      if (request.url.includes("/" + environment.user)) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + defaultAuthData
          }
        });
        return next.handle(request);
      }

      this._currentUser = this._authService.getCurrentUser;

      if (this._currentUser && this._currentUser.authdata) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + this._currentUser.authdata
          }
        });
      } else {
        console.warn('Kullanıcı verisi mevcut değil veya geçersiz.');
      }
    } else {
      console.warn('BasicAuthInterceptor yalnızca tarayıcı ortamında çalışacak şekilde yapılandırılmıştır.');
    }

    return next.handle(request);
  }
}
