import { Directive, ElementRef, Input, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common'; // Platform kontrolü için gerekli
//import * as Waves from 'node-waves'; // Kütüphane

@Directive({
  selector: '[rippleEffect]'
})
export class RippleEffectDirective implements OnInit {
  private _nativeElement: any;
  @Input() wave: string;

  constructor(
    private _elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object // PLATFORM_ID'yi inject ediyoruz
  ) {}

  ngOnInit(): void {
    // Get the native element
    this._nativeElement = this._elementRef.nativeElement;

    // Tarayıcı ortamında olup olmadığımızı kontrol ediyoruz
    if (isPlatformBrowser(this.platformId)) {
      // Eğer tarayıcı ortamındaysak Waves'i başlatıyoruz
      if (
        !this._nativeElement.className.split(' ').some(function (c) {
          return /btn-outline-.*/.test(c);
        }) &&
        !this._nativeElement.className.split(' ').some(function (c) {
          return /btn-flat-.*/.test(c);
        })
      ) {
        //Waves.attach(this._nativeElement, ['waves-float', 'waves-light']);
      } else {
        // Attach ripple with transparent style i.e flat, outline variant of button
        //Waves.attach(this._nativeElement);
      }
    }
  }
}
