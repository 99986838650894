
export const environment = {
    production: false,
    hmr: false,
    baseApiUrl: 'api/v1',
    crmBaseUrl: 'https://www.kahramanmaraskulturturizm.com/kultur',
    weburl: 'https://kahramansmaraskulturturizm.com',
    defaultUserName: 'user',
    defaultPassword: '1',
    crmimages: 'https://www.kahramanmaraskulturturizm.com/kultur/?entryPoint=image&id=',
    images: '?entryPoint=image&id=',
    avatar: '?entryPoint=avatar&size=small&id=',
    report: 'Report/action/runList?id=',
    reportGrid: 'Report/action/run?id=',
    user: 'User',
    userSelect: '',
    appUser: 'App/user',
    forgotPasswordUrl: 'https://www.kahramanmaraskulturturizm.com/kultur/#',
    forgotPassword: 'passwordChangeRequest',
    changePassword: 'changePasswordByRequest',
    account: 'Account',
    accountSelect: 'id,name,deleted,website,emailAddress,phoneNumber,type,industry,sicCode,billingAddressStreet,billingAddressCity,billingAddressState,billingAddressCountry,billingAddressPostalCode,shippingAddressStreet,shippingAddressCity,shippingAddressState,shippingAddressCountry,shippingAddressPostalCode,description,createdAt,modifiedAt,sicilNoTC,vergiNo,populer,rate,emailAddressIsOptedOut,phoneNumberIsOptedOut,emailAddressData,phoneNumberData,campaignId,campaignName,createdById,createdByName,modifiedById,modifiedByName,assignedUserId,assignedUserName,teamsIds,teamsNames,originalLeadId,originalLeadName,logoId,logoName,rateAndCommentsIds,rateAndCommentsNames,industryyId,industryyName,isFollowed,followersIds,followersNames',
    rateAndComment: 'RateAndComment',
    rateAndCommentSelect: '',
    rateAndCommentSubEntity: 'rateAndComments',
    mapAccesToken: 'pk.eyJ1IjoibWFrZ3VubjQ2IiwiYSI6ImNsMTBpbTlpNTA3ZjAzZm1ncm14OXgxNWMifQ.NwZ0iu7DvVxPAMB80_S6pg',
    etiket: 'Etiket',
    varlik: 'Varlk',
    varlikSelect: 'id,name,deleted,description,onBilgi,createdAt,modifiedAt,tur, envanterKodu,xKoordinat,yKoordinat,genelBilgi,adresStreet,adresCity,adresState,adresCountry,adresPostalCode,createdById,createdByName,modifiedById,modifiedByName,assignedUserId,assignedUserName,teamsIds,teamsNames,resimVideoId,resimVideoName,etiketsIds,etiketsNames,yorumsIds,yorumsNames,menuId,menuName,resimIdsiId,resimIdsiName,galeriIds,galeriNames,galeriTypes',
    favouriteVarlik: 'Favori',
    favouriteVarlikSelect: '',
    commentVarlik: 'Yorum',
    commentVarlikSelect: '',
    rotalar: 'Rotalar',
    offer: '',
    offerSelect: '',
};
