import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule } from "@core/components";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FileUploadModule } from "ng2-file-upload";
import { EtiketCardComponent } from './etiket-card/etiket-card.component';

@NgModule({
    declarations : [
        EtiketCardComponent,
    ],
    imports : [
        CommonModule,
        CoreSidebarModule,
        CoreCommonModule,
        //PerfectScrollbarModule,
        NgbModule,
        FileUploadModule,
        NgxDatatableModule,
        NgSelectModule
    ],
    providers : [],
    exports : [EtiketCardComponent]
})
export class ComponentsModule{

}
