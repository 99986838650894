import {Component, ElementRef, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation, Inject, PLATFORM_ID} from '@angular/core';

import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreMediaService} from '@core/services/media.service';

import {User} from 'app/models';

import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {HOMEPAGE, LOGIN} from '../../../lib/routes/routes';
import {environment} from '../../../../environments/environment';
import {EtiketService} from '../../../services/etiket.service';
import {getRoutes} from 'app/lib/helpers/getRoutes';
import {RotalarService} from '../../../services/rota.service';
import { HttpClient } from '@angular/common/http';
import {VarlikService} from '../../../services/varlik.service';
import {UserService} from 'app/services/user.service';
import {UtilsService} from 'app/services/utilsService.service';
import Speech from 'speak-tts';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {

    public mobileMenu: boolean = false;

    public _ilceEtiket: any = [];
    public secilietiket: any = {};
    public ilceidler: any = ['onikisubat', 'afsin', 'caglayancerit', 'nurhak', 'turkoglu', 'pazarcik', 'dulkadiroglu', 'elbistan', 'andirin', 'goksun', 'ekinozu'];
    public ROUTES = getRoutes();
    public _sureliRota: any = [];
    public _tumRotaDetay: any = [];
    public _sureliRotaDetay: any = [];
    public _secilisurelirota: any = {};
    public _secilidetay: any;
    public deneyimler: any = [];
    public navbarvarliklarimiz: any = [];
    public renk: any = 'red';
    public chtboxhide: any = true;
    public randomEtiketler: any = [];
    public etiketbuttons: any = [];

    public chatboxlist: any = [];
    searchtextetiket: any = '';
    public _selectedEtiket: any[] = [];
    searchsecilietiket: any = {};
    envire: any;
    public _userList: User[] = [];
    public _currentUser: User;

    speechData: any;
    public result: any = '';
    public speech: any;
    public voicecount: any = 0;

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _coreConfigService: CoreConfigService,
        private _coreMediaService: CoreMediaService,
        private _coreSidebarService: CoreSidebarService,
        public _translateService: TranslateService,
        public _etiketService: EtiketService,
        private _varlikService: VarlikService,
        private router: Router,
        public _rotaService: RotalarService,
        private _userService: UserService,
        protected _http: HttpClient,
        private _utilsService: UtilsService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.envire = environment;
        this.speech = new Speech(); // will throw an exception if not browser supported
        if (this.speech.hasBrowserSupport()) { // returns a boolean
            this.speech.init({
                'volume': 1,
                'lang': 'tr-TR',
                'rate': 1,
                'pitch': 1,
                'voice': 'Yelda tr-TR',
                'splitSentences': true,
                'listeners': {
                    'onvoiceschanged': (voices) => {
                        // console.log('Event voiceschanged', voices);
                    }
                }
            }).then((data) => {
                // The "data" object contains the list of available voices and the voice synthesis params
                //console.log('Speech is ready, voices are available', data);
                this.speechData = data;
                data.voices.forEach(voice => {
                    //console.log(voice.name + ' ' + voice.lang);
                });
            }).catch(e => {
                //console.error('An error occured while initializing : ', e);
            });
        }
        // if (this.currentUser) {
        // } else {
        //     this._authenticationService.login(environment.defaultUserName, environment.defaultPassword).subscribe(data => {
        //             this.currentUser = data.user;
        //             this._authenticationService.setCurrentUserAndLoginnonuser(data.user, 'user', '1');
        //         },
        //         error => {
        //             console.log('---hatalisifre--->', error);
        //         }
        //     );
        // }
        this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
        this.languageOptions = {
            en: {
                title: 'English',
                flag: 'us'
            },
            fr: {
                title: 'French',
                flag: 'fr'
            },
            de: {
                title: 'German',
                flag: 'de'
            },
            pt: {
                title: 'Portuguese',
                flag: 'pt'
            }
        };
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        _router.events.subscribe((val) => {
            // burası sayfa değiştiğinde çalışan kod
        });
    }

    menuOpenOrClose() {
        this.mobileMenu = !this.mobileMenu;
    }

    secilenetiket(i, item, et) {
        item.dahafazla = false;
        item.disabled = true;
        this._selectedEtiket.push({id: et.id, name: et.name});

        let str = '';
        for (var ii = 0; ii < this._selectedEtiket.length; ii++) {
            if (ii === 0) {
                str = this._selectedEtiket[ii].name;
            } else {
                str = str + ' - ' + this._selectedEtiket[ii].name;
            }
        }
        const obj = {
            text1: 'Seçimleriniz',
            text2: '',
            oneri: this.etiketbuttons,
            secimleriniz: '' + str,
            type: 'user',
            disabled: false,
            dahafazla: false
        };
        //console.log('1- --- Seçilen Etiket');
        this.chatboxlist.push(obj);
        let abc = 0;
        let filter = `?orderBy=createdAt&order=desc&select=etiketsIds,etiketsNames,name,resimIdsiId&where[0][type]=linkedWith&where[0][type]=and`;
        // tslint:disable-next-line:no-shadowed-variable
        this._selectedEtiket.map((item) => {
            const newFilter = `&where[0][value][${abc}][type]=linkedWith&where[0][value][${abc}][attribute]=etikets&where[0][value][${abc}][value][0]=${item.id}`;
            filter += newFilter;
            abc++;
        });
        this._varlikService.getListByWhere(null, filter, 0, 30).subscribe((data) => {
            this.etiketbuttons = [];
            const varlikNewList = data.list;
            let eklendimi = false;
            varlikNewList.map((item) => {

                //console.log("İTEMM--->>>",item)
                if (item.etiketsIds.length > 0) {
                    item.etiketsIds.map((etiketID) => {
                        this._selectedEtiket.map((sel) => {
                            const index = this.etiketbuttons.findIndex(item => item.id === etiketID);
                            const index2 = this._selectedEtiket.findIndex(item => item.id === etiketID);
                            if (index < 0 && index2 < 0) {
                                eklendimi = true;
                                this.etiketbuttons.push({
                                    id: etiketID,
                                    name: item.etiketsNames[etiketID],
                                });
                            }
                        });
                    });
                }

            });
            if (eklendimi) {
                //console.log('DEVAM EDİLEBİLİR ');
                const obj2 = {
                    text1: 'Seçiminiz ile ilgili diğer etiketler!',
                    text2: '',
                    oneri: this.etiketbuttons,
                    secimleriniz: this._selectedEtiket,
                    type: 'qyubi',
                    disabled: false,
                    dahafazla: true
                };

                let strvarliklar = '';
                for (var ii1 = 0; ii1 < this._selectedEtiket.length; ii1++) {
                    strvarliklar = strvarliklar + ' - ' + this._selectedEtiket[ii1].name;
                }
                this.textrec(strvarliklar + ' Seçiminiz ile ilgili diğer etiketler!');
                //console.log('2- --- Seçilen Etiket');
                this.chatboxlist.push(obj2);
            } else {
                //console.log('YENİ ETİKET GELMEDİ');
                if (data.list.length > 0) {
                    const obj2 = {
                        text1: 'Varlıklar!',
                        text2: '',
                        oneri: this.etiketbuttons,
                        secimleriniz: this._selectedEtiket,
                        type: 'result',
                        result: data.list,
                        disabled: false,
                        dahafazla: true
                    };
                    //console.log('3- --- Seçilen Etiket');
                    this.chatboxlist.push(obj2);
                } else {
                    const obj2 = {
                        text1: 'Seçilen kriterde kültürel varlık bulunamadı.',
                        text2: 'Site içinde araştırma yapabilirsiniz.',
                        oneri: this.etiketbuttons,
                        secimleriniz: this._selectedEtiket,
                        type: 'resultbos',
                        result: [],
                        disabled: false,
                        dahafazla: true
                    };
                    this.textrec('Seçilen kriterde kültürel varlık bulunamadı. Site içinde araştırma yapabilirsiniz.');
                    //console.log('4- --- Seçilen Etiket');
                    this.chatboxlist.push(obj2);
                }
            }
        });
    }

    changeEtiket(item, et) {
        item.dahafazla = false;
        item.disabled = true;
        this._selectedEtiket.push({id: et.id, name: et.name});
        let str = '';
        for (let ii = 0; ii < this._selectedEtiket.length; ii++) {
            if (ii === 0) {
                str = this._selectedEtiket[ii].name;
            } else {
                str = str + ' - ' + this._selectedEtiket[ii].name;
            }
        }
        const obj = {
            text1: 'Seçimleriniz',
            text2: '',
            oneri: this.etiketbuttons,
            secimleriniz: '' + str,
            type: 'user',
            disabled: false,
            dahafazla: false
        };
        //console.log('5- --- Seçilen Etiket');
        this.chatboxlist.push(obj);
        let abc = 0;
        let filter = `?orderBy=createdAt&order=desc&select=etiketsIds,etiketsNames,name,resimIdsiId&where[0][type]=linkedWith&where[0][type]=and`;
        this._selectedEtiket.map((item) => {
            const newFilter = `&where[0][value][${abc}][type]=linkedWith&where[0][value][${abc}][attribute]=etikets&where[0][value][${abc}][value][0]=${item.id}`;
            filter += newFilter;
            abc++;
        });
        this._varlikService.getListByWhere(null, filter, 0, 30).subscribe((data) => {
            this.etiketbuttons = [];
            const varlikNewList = data.list;
            let eklendimi = false;
            varlikNewList.map((item) => {

                //console.log("İTEMM--->>>",item)
                if (item.etiketsIds.length > 0) {
                    item.etiketsIds.map((etiketID) => {
                        this._selectedEtiket.map((sel) => {
                            const index = this.etiketbuttons.findIndex(item => item.id === etiketID);
                            const index2 = this._selectedEtiket.findIndex(item => item.id === etiketID);
                            if (index < 0 && index2 < 0) {
                                eklendimi = true;
                                this.etiketbuttons.push({
                                    id: etiketID,
                                    name: item.etiketsNames[etiketID],
                                });
                            }
                        });
                    });
                }

            });
            if (eklendimi) {
                //console.log('DEVAM EDİLEBİLİR ');
                const obj2 = {
                    text1: 'Seçiminiz ile ilgili diğer etiketler!',
                    text2: '',
                    oneri: this.etiketbuttons,
                    secimleriniz: this._selectedEtiket,
                    type: 'qyubi',
                    disabled: false,
                    dahafazla: true
                };
                let strvarliklar = '';
                for (var ii1 = 0; ii1 < this._selectedEtiket.length; ii1++) {
                    strvarliklar = strvarliklar + ' - ' + this._selectedEtiket[ii1].name;
                }
                this.textrec(strvarliklar + ' Seçimleriniz ile ilgili diğer ilgili etiketler.' + '');
                //console.log('6- --- Seçilen Etiket');
                this.chatboxlist.push(obj2);
                this.searchsecilietiket = {};
            } else {
                //console.log('YENİ ETİKET GELMEDİ');
                /*const obj2 = {
                    text1: 'Varlıklar!',
                    text2: '',
                    oneri: this.etiketbuttons,
                    secimleriniz: this._selectedEtiket,
                    type: 'result',
                    result: data.list,
                    disabled: false,
                    dahafazla: true
                };
                this.chatboxlist.push(obj2);
                */
                if (data.list.length > 0) {
                    const obj2 = {
                        text1: 'Varlıklar!',
                        text2: '',
                        oneri: this.etiketbuttons,
                        secimleriniz: this._selectedEtiket,
                        type: 'result',
                        result: data.list,
                        disabled: false,
                        dahafazla: true
                    };

                    let strvarliklar = '';
                    for (var ii1 = 0; ii1 < this._selectedEtiket.length; ii1++) {
                        strvarliklar = strvarliklar + ' - ' + this._selectedEtiket[ii1].name;
                    }
                    this.textrec('Arama kriterine göre kültürel varlıklarımız.' + strvarliklar);
                    //console.log('7- --- Seçilen Etiket');
                    this.chatboxlist.push(obj2);
                } else {
                    const obj2 = {
                        text1: 'Seçilen kriterde kültürel varlık bulunamadı.',
                        text2: 'Site içinde araştırma yapabilirsiniz.',
                        oneri: this.etiketbuttons,
                        secimleriniz: this._selectedEtiket,
                        type: 'resultbos',
                        result: [],
                        disabled: false,
                        dahafazla: true
                    };

                    let strvarliklar = '';
                    for (var ii1 = 0; ii1 < this._selectedEtiket.length; ii1++) {
                        strvarliklar = strvarliklar + ' - ' + this._selectedEtiket[ii1].name;
                    }
                    this.textrec('Seçilen kritere göre kültürel varlık bulunamadı. Site içinde arama yapabilirsiniz');
                    this.chatboxlist.push(obj2);
                }

                this.searchsecilietiket = {};
            }
        });
    }

    openProfileModal(profileModal: any) {
        this._utilsService.openModal(profileModal, {
            size: 'sm',
            backdrop: 'static',
            centered: true,
        });
    }

    closeProfileModal(modal: any) {
        this._utilsService.closeModal(modal);
    }

    finish(item, et) {

        item.dahafazla = false;
        item.disabled = true;

        //this._selectedEtiket.push({id: et.id, name: et.name});

        let str = '';
        for (var ii = 0; ii < this._selectedEtiket.length; ii++) {
            if (ii === 0) {
                str = this._selectedEtiket[ii].name;
            } else {
                str = str + ' - ' + this._selectedEtiket[ii].name;
            }
        }
        const obj = {
            text1: 'Seçimleriniz',
            text2: '',
            oneri: this.etiketbuttons,
            secimleriniz: '' + str,
            type: 'user',
            disabled: false,
            dahafazla: false
        };
        this.chatboxlist.push(obj);
        let abc = 0;
        let filter = `?orderBy=createdAt&order=desc&select=etiketsIds,etiketsNames,name,resimIdsiId&where[0][type]=linkedWith&where[0][type]=and`;
        this._selectedEtiket.map((item) => {
            const newFilter = `&where[0][value][${abc}][type]=linkedWith&where[0][value][${abc}][attribute]=etikets&where[0][value][${abc}][value][0]=${item.id}`;
            filter += newFilter;
            abc++;
        });
        this._varlikService.getListByWhere(null, filter, 0, 30).subscribe((data) => {
            this.etiketbuttons = [];
            const varlikNewList = data.list;
            const obj2 = {
                text1: 'Varlıklar!',
                text2: '',
                oneri: [],
                secimleriniz: '',
                type: 'result',
                result: data.list,
                disabled: false,
                dahafazla: true
            };
            let strvarliklar = '';
            for (var ii1 = 0; ii1 < data.list.length; ii1++) {
                strvarliklar = strvarliklar + ' - ' + data.list[ii1].name;
            }
            this.textrec(' Arama kriterinize göre kültürel varlıklarımız.' + strvarliklar);
            this.chatboxlist.push(obj2);

            //console.log('chatbox----->', this.chatboxlist);
            //console.log('----1-->', this.etiketbuttons);
            //console.log('-----1->', this._selectedEtiket);
        });

    }

    gondertext() {

        this.searchtextetiket = '';
    }

    getRandomEtiket() {

        this.etiketbuttons = [];
        for (let i = 0; i < 3; i++) {
            const index = (Math.random() * this.randomEtiketler.length);

            this.etiketbuttons.push(this.randomEtiketler[Math.floor(Math.random() * this.randomEtiketler.length)]);
        }
        const obj = {
            type: 'qyubi',
            text1: 'Merhaba, size nasıl yardımcı olabilirim?',
            text2: 'Bilgi sahibi olduğum bazı konular:',
            oneri: this.etiketbuttons,
            disabled: false,
            dahafazla: false
        };
        this.chatboxlist.push(obj);

    }

    /**
     * On init
     */
    ngOnInit(): void {
        // console.log(this.chtboxhide);
        this.getUnitList();
        // console.log('-----NGONİNIT-----');
        this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
        // Subscribe to the config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
            this.horizontalMenu = config.layout.type === 'horizontal';
            this.hiddenMenu = config.layout.menu.hidden === true;
            this.currentSkin = config.layout.skin;
            // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
            if (this.coreConfig.layout.type === 'vertical') {
                setTimeout(() => {
                    if (this.coreConfig.layout.navbar.type === 'fixed-top') {
                        this.isFixed = true;
                    }
                }, 0);
            }
        });

        if (this.currentUser) {
            this._etiketService.defaultGetList('Etiket', {
                    'where[0][type]': 'in',
                    'where[0][attribute]': 'tip',
                    'where[0][value][0]': 'İlçe',
                    'maxSize': '11',
                    'offset': '0',
                    'orderBy': 'createdAt',
                    'order': 'desc'
                }
            ).subscribe(data => {
                this._ilceEtiket = data.list;
                this.secilietiket = this._ilceEtiket[0];
            });

            const filter = {
                'select': 'id,name,rotatype,pictureId,pictureName,description,rotaDetaiesIds,rotaDetaiesNames',
                'maxSize': '20',
                'offset': '0',
                //'orderBy': 'createdAt',
                //'order': 'desc',
                'orderBy': 'sirasi',
                'order': 'asc',
                'where[0][type]': 'isTrue',
                'where[0][attribute]': 'navbar'

            };
            this._rotaService.defaultGetList('Rotalar', filter).subscribe(data => {
                this._sureliRota = data.list;
                this._secilisurelirota = data.list[0];
                const filterrotadetay = {
                    'select': 'id,sira,varlkId,vgenelbilgisi,varlkName,description,yKoordinat,xKoordinat,rotalarId,rotalarName,resimId,resimName,name',
                    'maxSize': '40',
                    'offset': '0',
                    'orderBy': 'sira',
                    'order': 'asc',
                    'where[0][type]': 'in',
                    'where[0][attribute]': 'rotalarId',
                };
                for (var ir = 0; ir < this._sureliRota.length; ir++) {
                    filterrotadetay['where[0][value][' + ir + ']'] = this._sureliRota[ir].id;
                }
                this._rotaService.defaultGetList('RotaDetay', filterrotadetay).subscribe(datadetay => {
                    this._tumRotaDetay = datadetay.list;
                    this.rotamouseover(data.list[0]);
                });
            });

            const filterdeneyim = {
                'select': 'id,name,resimId,resimName',
                'maxSize': '30',
                'offset': '0',
                'orderBy': 'createdAt',
                'order': 'desc',
                'where[0][type]': 'equals',
                'where[0][attribute]': 'deneyimId',
                'where[0][value]': '62615527822111970'
            };
            this._rotaService.defaultGetList('Etiket', filterdeneyim).subscribe(datadeneyim => {
                this.deneyimler = datadeneyim.list;
            });

            const filtervarlik = {
                'select': 'id,name,resimId,resimName',
                'maxSize': '30',
                'offset': '0',
                'orderBy': 'createdAt',
                'order': 'desc',
                'where[0][type]': 'equals',
                'where[0][attribute]': 'deneyimId',
                'where[0][value]': '65365c8eddd502825'
            };
            this._rotaService.defaultGetList('Etiket', filtervarlik).subscribe(datadeneyim => {
                this.navbarvarliklarimiz = datadeneyim.list;
            });

            const randometiket = {
                'select': 'id,name,resimId,resimName',
                'maxSize': '199',
                'offset': '0',
                'orderBy': 'createdAt',
                'order': 'desc'
            };
            this._rotaService.defaultGetList('Etiket', randometiket).subscribe(dataetik => {
                this.randomEtiketler = dataetik.list;
                this.getRandomEtiket();
            });


        } else {
            this._authenticationService.login(environment.defaultUserName, environment.defaultPassword).subscribe(data => {
                    this.currentUser = data.user;
                    this._authenticationService.setCurrentUserAndLoginnonuser(data.user, 'user', '1');
                    this._etiketService.defaultGetList('Etiket', {
                            'where[0][type]': 'in',
                            'where[0][attribute]': 'tip',
                            'where[0][value][0]': 'İlçe',
                            'maxSize': '11',
                            'offset': '0',
                            'orderBy': 'createdAt',
                            'order': 'desc'
                        }
                    ).subscribe(data => {
                        this._ilceEtiket = data.list;
                        this.secilietiket = this._ilceEtiket[0];
                    });
                    const filter = {
                        'select': 'id,name,rotatype,pictureId,pictureName,description,rotaDetaiesIds,rotaDetaiesNames',
                        'maxSize': '20',
                        'offset': '0',
                        //'orderBy': 'createdAt',
                        //'order': 'desc',
                        'orderBy': 'sirasi',
                        'order': 'asc',

                        'where[0][type]': 'isTrue',
                        'where[0][attribute]': 'navbar'
                    };

                    this._rotaService.defaultGetList('Rotalar', filter).subscribe(data2 => {
                        this._sureliRota = data2.list;
                        this._secilisurelirota = data2.list[0];
                        const filterrotadetay = {
                            'select': 'id,sira,varlkId,vgenelbilgisi,varlkName,description,yKoordinat,xKoordinat,rotalarId,rotalarName,resimId,resimName,name',
                            'maxSize': '40',
                            'offset': '0',
                            'orderBy': 'sira',
                            'order': 'asc',
                            'where[0][type]': 'in',
                            'where[0][attribute]': 'rotalarId',
                        };
                        for (let ir = 0; ir < this._sureliRota.length; ir++) {
                            filterrotadetay['where[0][value][' + ir + ']'] = this._sureliRota[ir].id;
                        }
                        this._rotaService.defaultGetList('RotaDetay', filterrotadetay).subscribe(datadetay => {
                            this._tumRotaDetay = datadetay.list;
                            this.rotamouseover(data.list[0]);
                        });
                    });

                    const filterdeneyim = {
                        'select': 'id,name,resimId,resimName',
                        'maxSize': '30',
                        'offset': '0',
                        'orderBy': 'createdAt',
                        'order': 'desc',
                        'where[0][type]': 'equals',
                        'where[0][attribute]': 'deneyimId',
                        'where[0][value]': '62615527822111970'
                    };
                    this._rotaService.defaultGetList('Etiket', filterdeneyim).subscribe(datadeneyim => {
                        this.deneyimler = datadeneyim.list;
                    });
                    const randometiket = {
                        'select': 'id,name,resimId,resimName',
                        'maxSize': '199',
                        'offset': '0',
                        'orderBy': 'createdAt',
                        'order': 'desc'
                    };
                    this._rotaService.defaultGetList('Etiket', randometiket).subscribe(dataetik => {
                        this.randomEtiketler = dataetik.list;
                        this.getRandomEtiket();
                    });
                },
                error => {
                }
            );
        }
        this._etiketService.defaultGetList('Ses', {
                'where[0][type]': 'isTrue',
                'where[0][attribute]': 'active'
            }
        ).subscribe(datavoice => {
            this.voicecount = datavoice.total;
        });
        // Horizontal Layout Only: Add class fixed-top to navbar below large screen
        if (this.coreConfig.layout.type === 'horizontal') {
            // On every media(screen) change
            this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {

            });
        }

        // Set the selected language from default languageOptions
        this.selectedLanguage = _.find(this.languageOptions, {
            id: this._translateService.currentLang
        });

    }

    getUnitList() {
        this._userService.getList().subscribe(
            (response) => {
                this._userList = response.list;
            }
        );
    }

    girisyapan() {
        try {
            if (this.currentUser['userName'] == 'user') {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }


    public horizontalMenu: boolean;
    public hiddenMenu: boolean;

    public coreConfig: any;
    public currentSkin: string;
    public prevSkin: string;

    public currentUser: User;

    public languageOptions: any;
    public navigation: any;
    public selectedLanguage: any;

    @HostBinding('class.fixed-top')
    public isFixed = false;

    @HostBinding('class.navbar-static-style-on-scroll')
    public windowScrolled = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    // Search input visibility
    public _searchEnable = false;


    // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (isPlatformBrowser(this.platformId)) {
        if (
            (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
            this.coreConfig.layout.navbar.type === 'navbar-static-top'
        ) {
            this.windowScrolled = true;
        } else if (
            (this.windowScrolled && window.pageYOffset) ||
            document.documentElement.scrollTop ||
            document.body.scrollTop < 10
        ) {
            this.windowScrolled = false;
        }
        }
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebar(key): void {
        this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    }

    /**
     * Toggle searchinput open
     *
     * @param key
     */
    toggleSearchVisibility(): void {
        this._searchEnable = !this._searchEnable;

    }

    /**
     * Set the language
     *
     // tslint:disable-next-line:no-redundant-jsdoc
     * @param language
     */
    setLanguage(language): void {
        // Set the selected language for the navbar on change
        this.selectedLanguage = language;

        // Use the selected language id for translations
        this._translateService.use(language);
    }

    /**
     * Toggle Dark Skin
     */
    toggleDarkSkin() {
        // Get the current skin
        this._coreConfigService
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.currentSkin = config.layout.skin;
            });

        // Only access localStorage if running in the browser
        if (isPlatformBrowser(this.platformId)) {
            // Toggle Dark skin with prevSkin skin
            this.prevSkin = localStorage.getItem('prevSkin');

            if (this.currentSkin === 'dark') {
                this._coreConfigService.setConfig(
                    { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
                    { emitEvent: true }
                );
            } else {
                localStorage.setItem('prevSkin', this.currentSkin);
                this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
            }
        }
    }

    /**
     * Logout method
     */
    logout() {
        // Only access localStorage if running in the browser
        if (isPlatformBrowser(this.platformId)) {
            //localStorage.removeItem('username');
            localStorage.removeItem('password');
        }

        this._authenticationService.logout();
        this._router.navigate([LOGIN]);
    }
    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------


    title: any = 'NgChatBot';
    messages = [
        {
            'content': 'Merhaba, size nasıl yardımcı olabilirim? Bilgi sahibi olduğum bazı konular:',
            'type': 'response',
            'buttons': this.getRandom(3),
            'csstip': 'question'
        }
    ];

    post_data = {
        'user_input': new Array(),
        'from_suggest': '',
        'ignore_suggest': 'False'
    };
    get_data = new Object();

    last_button: any = '';

    varlikgeldi: any = false;
    varliklar: any = [];
    oncekiselectedsvg: '';

    getRandom(n: any) {
        const tags = ['Onikişubat', 'Dulkadiroğlu', 'Türkoğlu', 'Pazarcık',
            'Nurhak', 'Göksun', 'Elbistan', 'Ekinözü', 'Çağlayancerit', 'Andırın',
            'Afşin', 'Yöresel Ağız', 'Ozanlar', 'Şairler', 'Yazarlar', 'Türbeler',
            'Doğa Sporları', 'Kurtuluş Dönemi', 'Osmanlı Eserleri', 'Arkeoloji',
            'Mesire Alanları', 'Mağaralar', 'Yaylalar', 'Kamp', 'Müzeler', 'Kaleler',
            'Zanaat', 'Sağlık', 'Alışveriş', 'İnanç', 'Gastro', 'DENEME', 'UNDEFINED'];
        let result = new Array(n), len = tags.length - 1, taken = new Array(len);
        if (n > len) {
            throw new RangeError('getRandom: more elements taken than available');
        }
        while (n--) {
            const x = Math.floor(Math.random() * len);
            result[n] = tags[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }

        return result;

    }

    async postData(type) {
        await this._http.post<Object>('http://46.31.148.171:8000/chatbot', this.post_data).forEach(element => {
            this.get_data = new Object();
            this.get_data = {...element};
            return;
        });
        this.messages.push({
            'content': this.post_data.user_input.toString(),
            'type': 'message',
            'buttons': [],
            'csstip': 'userinput'
        });

        this.messages.push({
            'content': Object.values(this.get_data)[2] === true ? 'İsteğiniz ile örtüşen bazı kategoriler bunlar. Hangisini incelemek istersiniz?' : Object.values(this.get_data)[0],
            'type': 'response',
            'buttons': Object.values(this.get_data)[2] === false ? [] : Object.values(this.get_data)[0],
            'csstip': 'respkategori'
        });
        if (Object.values(this.get_data)[2] === false) {
            await this.resetPostData();
        }

        this.post_data.user_input.splice(-1);
        this.post_data.user_input.push(await Object.values(this.get_data)[1].toString());


        if (this.messages[this.messages.length - 1].csstip == 'respkategori' && this.messages[this.messages.length - 1].type == 'response') {
            if (this.messages[this.messages.length - 1].buttons.length == 0) {

                this.varlikgeldi = true;
                this.varliklar = this.messages[this.messages.length - 1].content;
            } else {
                this.varlikgeldi = false;
                this.varliklar = [];
            }
        }
        if (isPlatformBrowser(this.platformId)) {
        setTimeout(function () {
            const element = document.getElementById('chatdivid');
            element.scrollTop += 575;
        }, 1);
    }

    }

    test(a) {

    }

    resetPostData() {
        this.post_data.user_input = [];

    }

    buttonClick(content: string, sayi: any) {
        if (content === 'NoSuggest') {
            this.post_data.from_suggest = 'True'; // butona basıldığı için true
            this.post_data.ignore_suggest = 'True'; // daha fazla filtre istemediği için true
        } else {
            this.post_data.user_input.push(content);
            this.post_data.from_suggest = 'True';
            this.post_data.ignore_suggest = 'False';
        }

        this.postData('button');

    }

    typeClick(content: string) {

        this.post_data.user_input.push(content);
        this.post_data.from_suggest = 'False';
        this.post_data.ignore_suggest = 'False';

        this.postData('type');
    }

    displaychatbox() {
        this.chtboxhide = !this.chtboxhide;

        this.post_data.user_input = [];
        let abc = this.getRandom(3);
        this.messages = [
            {
                'content': 'Merhaba, size nasıl yardımcı olabilirim? Bilgi sahibi olduğum bazı konular:',
                'type': 'response',
                'buttons': abc,
                'csstip': 'question'
            }
        ];
        this.chatboxlist = [];
        this.getRandomEtiket();
        if (!this.chtboxhide) {

            this.textrec('Merhaba, size nasıl yardımcı olabilirim? Bilgi sahibi olduğum bazı konular:');
        } else {

            this._selectedEtiket = [];
            this.speechstop();
        }
    }

    speechstop() {
        this.speech.cancel();
    }

    textrec(ses) {
        this.speech.speak({text: ses}).then(() => {
        }).catch(e => {
            console.error('An error occurred :', e);
        });
    }

    gomapilce(t?, x?, y?, v?, i?) {
        if (i == 1) {
            this.router.navigate(['map'], {state: null, queryParams: {type: 'ilce', x: x, y: y, varlikfilter: v}});
            return;
        }
        /*
        const konum = {
            x: x,
            y: y,
            ilce: ilce,
            frompage: 'ilceodak'
        };
        this._router.navigate(['map'], {state: konum});*/

        let xx = 37.5879578;
        let yy = 36.8831883;
        if (this.secilietiket.name === 'Onikişubat') {
            xx = 37.5879578;
            yy = 36.8831883;
        } else if (this.secilietiket.name === 'Dulkadiroğlu') {
            xx = 37.5789825;
            yy = 36.9695747;
        } else if (this.secilietiket.name === 'Andırın') {
            xx = 37.5746445;
            yy = 36.3531017;
        } else if (this.secilietiket.name === 'Türkoğlu') {
            xx = 37.3841023;
            yy = 36.8572468;
        } else if (this.secilietiket.name === 'Nurhak') {
            xx = 37.9654009;
            yy = 37.448205;
        } else if (this.secilietiket.name === 'Ekinözü') {
            xx = 38.0600276;
            yy = 37.1887265;
        } else if (this.secilietiket.name === 'Çağlayancerit') {
            xx = 37.7445878;
            yy = 37.2958773;
        } else if (this.secilietiket.name === 'Elbistan') {
            xx = 38.2075617;
            yy = 37.2016077;
        } else if (this.secilietiket.name === 'Pazarcık') {
            xx = 37.4857521;
            yy = 37.2890447;
        } else if (this.secilietiket.name === 'Göksun') {
            xx = 38.0214051;
            yy = 36.4984414;
        } else if (this.secilietiket.name === 'Afşin') {
            xx = 38.2462643;
            yy = 36.920267;
        }
        const konum = {
            x: xx,
            y: yy,
            frompage: 'ilceodak'
        };
        //this._router.navigate(['map'], {state: konum});
        this.router.navigate(['map'], {state: konum, queryParams: {type: 'ilce', x: xx, y: yy}});


    }

    enterfunc(div, ilc) {
        for (let i = 0; i < this._ilceEtiket.length; i++) {
            if (div === this._ilceEtiket[i].name) {
                this.secilietiket = this._ilceEtiket[i];
                this.secilietiket.x = '';
            }
        }
        if (isPlatformBrowser(this.platformId)) {
        for (let j = 0; j < this.ilceidler.length; j++) {
            if (ilc === this.ilceidler[j]) {
                const el = document.getElementById(ilc);
                el.style.opacity = '0.7';
            } else {
                const el2 = document.getElementById(this.ilceidler[j]);
                el2.style.opacity = `1 `;
            }
        }


        if (ilc == '') {
            try {

                const info2 = document.getElementById('ilce-isimleri');
                info2.innerHTML = [`<div></div>`].join('');
                return;

            } catch (e) {

            }
        }
        const av = document.getElementById(ilc);
        const ac = this.getOffset(av);

        const info = document.getElementById('ilce-isimleri');
        if (info) {
            info.style.top = `${ac.top - 50 + 0}px`;
            const a = 0;
            if (a === 0) {
                info.style.left = `${ac.left - 190}px`;
            } else {
                // info.style.left = `${event.pageX - 300}px`;
            }
            info.innerHTML = [`<div>${div}</div>`].join('');
        }
    }
    }

    getOffset(el) {

        try {

            const rect = el.getBoundingClientRect();
            return {
                left: rect.left + window.scrollX,
                top: rect.top + window.scrollY
            };
        } catch (e) {

        }
    }

    mouseOut() {
        if (isPlatformBrowser(this.platformId)) {
        document.getElementById('ilce-isimleri').innerHTML = '';
        }
    }

    mouseOver(event: any, ilAdi: string) {
        if (isPlatformBrowser(this.platformId)) {
        const info = document.getElementById('ilce-isimleri');
        if (info) {
            info.style.top = `${event.clientY - 90 + 0}px`;
            const a = 0;
            if (a === 0) {
                info.style.left = `${event.pageX - 180}px`;
            } else {
                // info.style.left = `${event.pageX - 300}px`;
            }
            if (event.target.tagName === 'path') {
                info.innerHTML = [`<div>${ilAdi}</div>`].join('');
            }
        }
    }
        let ilc = 'Onikişubat';
        let kilc = '';
        if (ilAdi === 'AFŞİN') {
            ilc = 'Afşin';
            kilc = 'afsin';
        } else if (ilAdi === 'ELBİSTAN') {
            ilc = 'Elbistan';
            kilc = 'elbistan';
        } else if (ilAdi === 'ONİKİŞUBAT') {
            ilc = 'Onikişubat';
            kilc = 'onikisubat';
        } else if (ilAdi === 'GÖKSUN') {
            ilc = 'Göksun';
            kilc = 'goksun';
        } else if (ilAdi === 'EKİNÖZÜ') {
            ilc = 'Ekinözü';
            kilc = 'ekinozu';
        } else if (ilAdi === 'NURHAK') {
            ilc = 'Nurhak';
            kilc = 'nurhak';
        } else if (ilAdi === 'DULKADİROĞLU') {
            ilc = 'Dulkadiroğlu';
            kilc = 'dulkadiroglu';
        } else if (ilAdi === 'ANDIRIN') {
            ilc = 'Andırın';
            kilc = 'andirin';
        } else if (ilAdi === 'ÇAĞLAYANCERİT') {
            ilc = 'Çağlayancerit';
            kilc = 'caglayancerit';
        } else if (ilAdi === 'PAZARCIK') {
            ilc = 'Pazarcık';
            kilc = 'pazarcik';
        } else if (ilAdi === 'TÜRKOĞLU') {
            ilc = 'Türkoğlu';
            kilc = 'turkoglu';
        }
        this.enterfunc(ilc, kilc);
    }

    filterDataByIlce(ilce, event, x, y) {
        this.gomapilce();
        return;
        const konum = {
            x: x,
            y: y,
            ilce: ilce,
            frompage: 'ilceodak'
        };
        this._router.navigate(['map'], {state: konum});
    }


    rotamouseover(rota) {
        this._secilisurelirota = rota;
        this._sureliRotaDetay = [];
        for (var i = 0; i < this._tumRotaDetay.length; i++) {
            if (this._secilisurelirota.id === this._tumRotaDetay[i].rotalarId) {
                this._sureliRotaDetay.push(this._tumRotaDetay[i]);
            }
        }

        try {
            this._secilidetay = this._sureliRotaDetay[0];
        } catch (e) {
        }
    }


    varlikmouseover(detay) {
        this._secilidetay = detay;
    }


    routevarlikdetay(varlik) {

        this.router.navigate(['varlik-detay'], {state: varlik, queryParams: {id: varlik.varlkId}});
    }

    goPhotoPage() {
        this.mobileMenu=!this.mobileMenu;
        this.router.navigate(['images']);
    }

    goVoicePage() {
        //this.router.navigate(['voices']);
        this.mobileMenu=!this.mobileMenu;
        this.router.navigate(['voices'], {state: null, queryParams: {id: null}});

    }

    goVideoPage() {
        this.mobileMenu=!this.mobileMenu;
        this.router.navigate(['video']);
    }

    goPage() {

        this.router.navigate(['varliklar']);
    }

    goVarlikDetail(varlik) {

        this.router.navigate(['varlik-detay'], {state: varlik, queryParams: {id: varlik.id}});
    }

    routemapvarlik(varlik) {
        this._etiketService.defaultGetRecord('Varlk', varlik.varlkId).subscribe(data => {
            const param = {
                frompage: 'varlik',
                varlik: data
            };
            this.router.navigate(['map'], {state: param});
        });
    }

    getVarlikOfEtikets(etiketss) {
        console.log(etiketss);
        this.router.navigate(['varlik'], {state: etiketss, queryParams: {id: etiketss.id}});

        // for mobile
        this.mobileMenu = !this.mobileMenu;
    }


    goMap() {
        const state = {
            frompage: 'navbardefault'
        };
        //this.router.navigate(['map'], {state: state});
        this.router.navigate(['map'], {state: state, queryParams: {type: 'default'}});
        //for mobile
        this.mobileMenu = !this.mobileMenu;
    }

    goMapAtlas() {
        window.open('https://kahramanmaraskulturturizm.com/map', '_blank');
        return;
        const state = {
            frompage: 'navbardefault'
        };
        //this.router.navigate(['map'], {state: state});
        this.router.navigate(['map'], {state: state, queryParams: {type: 'default'}});
        //for mobile
        this.mobileMenu = !this.mobileMenu;
    }

    goMapRota(rotas) {
        const state = {
            frompage: 'rotainfo',
            rota: rotas
        };
        if (isPlatformBrowser(this.platformId)) {
        try {
            document.getElementById('map').remove();
        } catch (e) {
        }
        }
        this.router.navigate(['map'], {state: state, queryParams: {id: rotas.id, type: 'rotainfo'}});
        //this.router.navigate(['map'], {state: state});
    }

    goRotaDetail(rota) {
        // this.router.navigate(['varlik'], {state: etiketss, queryParams: {id: etiketss.id}});
        this.router.navigate(['rota-detay'], {state: rota, queryParams: {id: rota.id}});
    }

    openGirisComponent() {
        this._router.navigate([LOGIN]);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    aaa() {
        this._authenticationService.logout();
    }

    renkS(renk) {

        if (this.secilietiket != '') {
            return 'red';
        } else {
            return 'green';
        }
    }
}
