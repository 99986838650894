import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Entity } from '../models/common/entity';
import { User } from '../models';
import { AuthenticationService } from './authentication.service';
import { BaseApiResponse } from '../models/common/baseApiResponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataTransporter } from 'app/models/common/dataTransporter';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

export class BaseService<T extends Entity> {
    protected _currentUser: User;
    private readonly _orderBy: string = 'orderBy=createdAt&order=desc';
    public _operationOccured: BehaviorSubject<DataTransporter<T>> = new BehaviorSubject(null);
    public authdatas: any;

    constructor(
        protected _authenticationService: AuthenticationService,
        protected _http: HttpClient,
        protected _entity: string,
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected _entitySelect?: string
    ) {
        this._currentUser = this._authenticationService.getCurrentUser;
        this._authenticationService.currentUserSubject.subscribe((user) => {
            this._currentUser = Object.assign({}, user);
        });

        if (isPlatformBrowser(this.platformId)) {
            this.authdatas = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
        }

        this._authenticationService
            .login(environment.defaultUserName, environment.defaultPassword)
            .subscribe(
                (data) => {
                    this._currentUser = data.user;
                    this._authenticationService.setCurrentUserAndLoginnonuser(data.user, 'user', '1');

                    if (isPlatformBrowser(this.platformId)) {
                        this.authdatas = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
                    }
                },
                (error) => {}
            );
    }


    getParamsFromBody(body) {
        let params = new HttpParams();
        if (body) {
            // tslint:disable-next-line:forin
            for (const key in body) {
                params = params.set(key, body[key]);
            }
        }
        return params;
    }

    defaultGetList(entty, bdy) {
        const body = this.getParamsFromBody(bdy);
        const url = `${environment.crmBaseUrl}/${environment.baseApiUrl}/` + entty;

        return this._http.get<BaseApiResponse<T>>(
            url,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                }),
                params: body
            });
    }

    defaultGetRecord(entty, id) {
        const url = `${environment.crmBaseUrl}/${environment.baseApiUrl}/` + entty + '/' + id;

        return this._http.get<BaseApiResponse<T>>(
            url,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            });
    }

    getListWithFilterText(filterText: string, maxSize: number = 10) {
        const filterTextValue = filterText ? `where[0][type]=textFilter&where[0][value]=*${filterText}*&` : '';
        if (this._currentUser.authdata) {
            return this._http.get<BaseApiResponse<T>>(
                `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}?${filterTextValue}&maxSize=${maxSize}&orderBy=createdAt&order=desc`
            );
        } else {
            return this._http.get<BaseApiResponse<T>>(
                `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}?${filterTextValue}&maxSize=${maxSize}&orderBy=createdAt&order=desc`,
                {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + this.authdatas
                    })
                }
            );
        }
    }

    getListByWhere(select?: string, where?: string, offset?: number, maxSize?: number) {

        select = !!select ? `select=${select}&` : '';
        where = !!where ? `${where}&` : '';
        const offsetValue = offset != undefined ? `&offset=${offset}&maxSize=${maxSize || 200}` : '';
        return this._http.get<BaseApiResponse<T>>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}?${select}${where}${this._orderBy}${offsetValue}`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    getListWithSelect(where?: string, offset?: number, maxSize?: number) {
        return this.getListByWhere(this._entitySelect, where, offset, maxSize);
    }

    getSubEntityList<T2>(id: string, subEntityName: string, select?: string) {
        const selectValue = !!select ? `select=${select}&` : '';
        return this._http.get<BaseApiResponse<T2>>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}/${id}/${subEntityName}?${selectValue}orderBy=createdAt&order=desc`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    getById(id: string) {
        return this._http.get<T>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}/${id}`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            });
    }

    insert(data: T) {
        return this._http.post<T>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    post(modl, data) {
        return this._http.post<T>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${modl}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    updateWithModel(data: T) {
        return this._http.put<T>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}/${data.id}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    updateWithBody(id: string, body: any) {
        return this._http.put<T>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}/${id}`,
            body,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    delete(id: string) {
        return this._http.delete<any>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}/${id}`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    etiketForHome() {
        return this._http.get<BaseApiResponse<T>>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}?orderBy=createdAt&order=desc`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
    }

    getList() {
        return this._http.get<BaseApiResponse<T>>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}?orderBy=createdAt&order=desc`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + this.authdatas
                })
            }
        );
        /*
        if (this._currentUser.authdata) {
            return this._http.get<BaseApiResponse<T>>(
                `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}?orderBy=createdAt&order=desc`
            )

        } else {
            return this._http.get<BaseApiResponse<T>>(
                `${environment.crmBaseUrl}/${environment.baseApiUrl}/${this._entity}?orderBy=createdAt&order=desc`,
                {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + this.authdatas
                    })
                }
            )
        }*/
    }

    getRouteGeojson(entty, bdy, ids) {
        const urlt = 'https://api.mapbox.com/directions/v5/mapbox/driving/' + ids
            + '?geometries=geojson&access_token=pk.eyJ1IjoibWFrZ3VubjQ2IiwiYSI6ImNsMTBpbTlpNTA3ZjAzZm1ncm14OXgxNWMifQ.NwZ0iu7DvVxPAMB80_S6pg';
        return this._http.get<BaseApiResponse<T>>(
            urlt,
            {
                headers: new HttpHeaders({}),
                params: {}
            });
    }
}
