import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Etiket } from '../models/entity/etiket';
import { Inject, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class EtiketService extends BaseService<Etiket> {
    constructor(public _authenticationService: AuthenticationService,
        public _http: HttpClient,
        @Inject(PLATFORM_ID) platformId: Object) {
        super(_authenticationService, _http, environment.etiket, platformId);
    }

    getListForHomePage() {
        return this.getListByWhere(null, 'where[0][type]=isTrue&where[0][attribute]=populer');
    }

    getListForFilter(filter: any, offset: number = 0, maxSize: number = 200) {
        let whereQuery = '';
        let whereCounter = 0;
        if (Object.keys(filter).some(k => !!filter[k])) {
            const search = filter['search'];
            const account = filter['account'];
            if (!!search) {
                // tslint:disable-next-line:triple-equals
                whereQuery += `${whereCounter == 0 ? '' : '&'}where[${whereCounter}][type]=textFilter&where[${whereCounter}][value]=%25${search}%25`;
                whereCounter++;
            }
            if (!!account?.id) {
                // tslint:disable-next-line:triple-equals
                whereQuery += `${whereCounter == 0 ? '' : '&'}where[${whereCounter}][type]=equals&where[${whereCounter}][attribute]=id`;
                whereQuery += `&where[${whereCounter}][value]=${account.id}`;
                whereCounter++;
            }
        }

        return this.getListWithSelect(whereQuery, offset, maxSize);
    }




}
