import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
})
export class Api extends BaseService<any> {
    constructor(
        public _authenticationService: AuthenticationService,
        public _http: HttpClient,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        super(_authenticationService, _http, environment.etiket, platformId);

        this._currentUser = this._authenticationService.getCurrentUser;
        this._authenticationService.currentUserSubject.subscribe((user) => {
            this._currentUser = Object.assign({}, user);
        });

        if (this._currentUser) {
            this.authdatas = this._currentUser.authdata;
        } else {
            this._authenticationService
                .login(environment.defaultUserName, environment.defaultPassword)
                .subscribe(
                    (data) => {
                        this._currentUser = data.user;
                        this._authenticationService.setCurrentUserAndLoginnonuser(data.user, 'user', '1');

                            this.authdatas = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
                    },
                    (error) => { }
                );

                this.authdatas = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
        }
    }
}
