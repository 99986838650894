import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'app/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EncDecrService } from './enc-decr.service';
import { Register } from '../models/auth/register';
import { environment } from '../../environments/environment';
import { ForgotPassword } from '../models/auth/forgotPassword';
import { ChangePassword } from '../models/auth/changePassword';
import { HOMEPAGE, LOGIN } from 'app/lib/routes/routes';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUser: Observable<User>;
    private responseUser: User = new User();
    public currentUserSubject: BehaviorSubject<User>;

    constructor(
        private _http: HttpClient,
        private _toastrService: ToastrService,
        private _router: Router,
        private _encDecrService: EncDecrService,
        @Inject(PLATFORM_ID) private platformId: Object // Inject the platform ID to check for browser/server
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(this.getCurrentUser ? this.getCurrentUser : null);
     
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get getCurrentUser(): User {
        if (isPlatformBrowser(this.platformId)) {
            try {
                const user = localStorage.getItem('currentUser');
                return user ? JSON.parse(this._encDecrService.get(user)) : null;
            } catch (error) {
                console.error('Kullanıcı verisi çözümlenirken hata oluştu:', error);
                return null;
            }
        }
        return null; // Server side'da null döndürüyoruz
    }
    
    public setCurrentUser(user: User) {
        if (user && isPlatformBrowser(this.platformId)) {
            localStorage.setItem('currentUser', this._encDecrService.set(JSON.stringify(user)));
            this.currentUserSubject.next(user);
        } else {
            console.error('User is undefined or null');
        }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    get isAdmin() {
        return this.getCurrentUser && this.getCurrentUser.type === 'admin';
    }

    get isClient() {
        return this.currentUserValue && this.currentUserValue.type === 'regular';
    }

    login(username: string, password: string) {
        return this.getCrmUser(username, password);
    }

    setCurrentUserAndLogin(user: any, username: string, password: string) {
        this.responseUser = this.convertResponseToUser(user);
        this.responseUser.authdata = window.btoa(username + ':' + password);
        this.responseUser.password = password;

        if (isPlatformBrowser(this.platformId)) {
            this._toastrService.success(
                'Giriş başarılı',
                '👋 Hoşgeldiniz, ' + this.responseUser.firstName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
            );

            this.setCurrentUser(this.responseUser);
        }
    }

    setCurrentUserAndLoginnonuser(user: any, username: string, password: string) {
        this.responseUser = this.convertResponseToUser(user);
        this.responseUser.authdata = window.btoa(username + ':' + password);
        this.responseUser.password = password;

        if (isPlatformBrowser(this.platformId)) {
            this.setCurrentUser(this.responseUser);
        }
    }

    logout() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('currentUser');
            if (this.currentUserValue) {  // Eğer kullanıcı hala `BehaviorSubject`'te mevcutsa
                this.currentUserSubject.next(null);  // Kullanıcıyı null yap
            }
            this._router.navigate([LOGIN]);
        }
    }

    register(register: Register) {
        const authData = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
        return this._http.post<any>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}`,
            register,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${authData}`
                })
            });
    }

    getCrmUser(username: string, password: string) {
        return this._http.get<any>(
            `${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.appUser}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`)
                })
            });
    }

    forgotPasswordSendEmail(forgotPassword: ForgotPassword) {
        return this._http.post<any>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}/${environment.forgotPassword}`,
            forgotPassword, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
    }

    changePassword(changePassword: ChangePassword) {
        return this._http.post<any>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}/${environment.changePassword}`,
            changePassword, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                })
            });
    }

    setCurrentDogrulamaKodu(dogrulamaKoduBody: any, id: string) {
        const authData = window.btoa(environment.defaultUserName + ':' + environment.defaultPassword);
        return this._http.put<User>(`${environment.crmBaseUrl}/${environment.baseApiUrl}/${environment.user}/${id}`,
            dogrulamaKoduBody, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${authData}`
                })
            });
    }

    private convertResponseToUser(responseUserObj: User): User {
        return responseUserObj;
    }
}
